import React from "react";

const MainContents = ({ leftColumnWidth }) => {
  return (
    <main className="px-4 py-2" style={{ marginLeft: leftColumnWidth }}>
      <h1>Security Engineering on AWS へようこそ！（<a href="https://aws.amazon.com/jp/training/classroom/security-engineering-on-aws/" target="blank">Security Engineering on AWS について</a>）</h1>
      <hr class="koji"/>
      <h3 id="prepare" class="anchor">初日の準備</h3>
      <div class="box">
        <h5>受講準備</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・ブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・本コースではマイクの使用は必須ではありません。スピーカー（やヘッドホンなど）を準備いただければ OK です。<br/>
        ・可能であればモニターは複数用意していただくと演習（ラボ）を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・音声が聞こえない方は P. 28 をご確認ください<br/>
        ===== <a href="https://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">【2】受講者ガイド利用手順</a> =====<br/>
        ・コース開始前に <a href="https://evantage.gilmoreglobal.com/" target="blank">こちら</a> からアカウントの作成をお願いします。<br/>
        ・講義で用いる教材は「Student Guide」、演習の手順書は「Lab Guide」となります。<br/>
        ・ただし、スライドの並び替えや取捨選択、補足資料の追加などをしておりますので、教材ではなく Webex の画面を見ながらの受講をお願いします。<br/>
        ・トレーニングの期間中に必ず 1 度は教材への閲覧をお願いします。<br/>
        ・教材は、引き換え後、720 日間閲覧可能です。<br/>
        ※ 稀に、引き換え後にガイドが表示されないことがあります。同期の問題であることが多いので、午後に [更新] ボタンを押してみてください。<br/>
        ====== 【3】受講者ポータルへのアクセス方法 =====<br/>
        ・ログインページは <a href="" target="blank">こちら(XXXXXXXX - XXXXXXXX)</a> です。<br/>
        ・受講者ポータルから、ラボ（ハンズオン）の実施や、教科書の閲覧ができます。<br/>
        ・研修を申し込む際に使用したアカウントでログインをお願いします。<br/>
        ・受講者ポータルでのラボ実施は、トレーニング中の3日間のみアクセス可能です。ご留意下さいませ。<br/>
        </p>
        <hr/>
        <h5>受講準備（Jam）</h5>
        <p>
        ===== 受講環境について =====<br/>
        ・同じくブラウザは Chrome もしくは Firefox をお使いください。<br/>
        ・極力 VPN は切断してください。（通信が不安定になる可能性があります）<br/>
        ・Jam はマイクの使用を推奨しております。コミュニケーションを円滑にするために使用下さい。<br/>
        ・可能であればモニターは複数用意していただくと「チャレンジ」を効率的に進めることができます。（手順書と操作画面を行ったり来たりする為）<br/>
        ===== <a href="https://a.co/7SeNx7p" target="blank">【1】配信ツール（Webex）の使い方</a> =====<br/>
        ・同じく音声が聞こえない方は P. 28 をご確認ください<br/>
        ====== 【2】登録に関する内容および Jam 用の補足資料に関して =====<br/>
        ・Jam への登録に関しては<a href="https://seceng-appendix-x.s3.ap-northeast-1.amazonaws.com/Jam_pre_register.pdf" target="blank">こちら（AWS Jam アカウント登録案内）</a>の資料をご参照下さい。<br/>
        ・上記につきましてまずはログインが必要になりますので講義内「受講者ポータル」にアクセス頂いた方法と同じ方法でログインの上、登録を進行して下さい。<br/>
        ・流れとしては　＜登録案内にある register URL へのアクセス＞→＜画面中央「AWS スキルビルダーでログイン」からログイン、「受講者ポータル」利用の際と同様で。＞→＜再度 1 点目の register URL へ＞<br/>
        ・Jam のオリエンテーション時にご案内している資料の抜粋版は<a href="https://seceng-appendix-x.s3.ap-northeast-1.amazonaws.com/Jam_Appendix.pdf" target="blank">こちら</a>になります。ダウンロードなど頂き、適宜ご確認下さい。<br/>
        ====== 【3】Jam 参加に関する URL のご紹介 =====<br/>
        ・Jam イベント（今回の限定）の URL は<a href="" target="blank">こちら</a>になります。抜けてしまった際のために URL はお控え下さい！<br/>
        </p>
        <hr/>
        <h5>付加資料に関して</h5>
        <p>
          <a href="https://seceng-appendix-x.s3.ap-northeast-1.amazonaws.com/SecurityEngineeringOnAWS_Module_Appendix.pdf" target="blank">トレーニング付加資料</a><br />
          <a href="https://seceng-appendix-x.s3.ap-northeast-1.amazonaws.com/SecurityEngineeringOnAWS_Lab_Appendix.pdf" target="blank">ラボのポイント (PDF)</a>
        </p>
        
      </div>
      <hr class="koji"/>
      <h3 id="agenda" class="anchor">アジェンダ</h3>
      <div class="box">
        <div class="blackboard-box">
          <p><b><span class="warn">下記はあくまで予定なので参考程度にお考えください</span></b></p>
          <p>みなさんが手を動かす時間を多めに取る予定です</p>
          <div class="chalk1"></div>
          <div class="chalk2"></div>
        </div>
        <h5>Day 1</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>1</td>
            <td>セキュリティの概要</td>
            <td></td>
          </tr>
          <tr>
            <td>2</td>
            <td>AWS におけるアクセスと権限付与</td>
            <td><span class="point_box"><b>ラボ1</b></span>アイデンティティ＆リソースベースポリシーの利用</td>
          </tr>
          <tr>
            <td>3</td>
            <td>AWS におけるアカウント管理とプロビジョニング</td>
            <td><span class="point_box"><b>ラボ2</b></span>AWS Directory Service によるドメインユーザーアクセスの管理（オプション）</td>
          </tr>
        </table>
        <hr/>
        <h5>Day 2</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>4</td>
            <td>AWS におけるキーとシークレットの管理</td>
            <td><span class="point_box"><b>ラボ3</b></span>AWS KMS を使用して Secrets Manager シークレットを暗号化する</td>
          </tr>
          <tr>
            <td>5</td>
            <td>データセキュリティ</td>
            <td><span class="point_box"><b>ラボ4</b></span>Amazon S3 のデータセキュリティ</td>
          </tr>
        </table>
        <hr/>
        <h5>Day 3</h5>
        <table>
          <tr>
            <th class="a">モジュール</th>
            <th class="b">タイトル</th>
            <th class="c">ラボ</th>
          </tr>
          <tr>
            <td>6</td>
            <td>インフラストラクチャとエッジ保護</td>
            <td><span class="point_box"><b>ラボ5</b></span>AWS WAF を使って悪意のあるトラフィックを軽減する</td>
          </tr>
          <tr>
            <td>7</td>
            <td>AWS におけるログのモニタリングと収集</td>
            <td><span class="point_box"><b>ラボ6</b></span>セキュリティインシデントの監視と対応</td>
          </tr>
          <tr>
            <td>8</td>
            <td>脅威への対応</td>
            <td><span class="point_box"><b>ラボ7</b></span>インシデント対応（オプション）</td>
          </tr>
          <tr>
            <td>エンディング</td>
            <td>コースのまとめ＆アンケート入力</td>
            <td></td>
          </tr>
        </table>
      </div>
      <hr class="koji"/>
      <h3 id="modules" class="anchor">参考リンク</h3>
      <div class="box">
        <h5>モジュール 1（セキュリティの概要）</h5>
        ・<a href="https://www.idnet.co.jp/column/page_004.html" target="blank">【エバンジェリスト・ボイス】CIA？AIC？情報セキュリティの3要素とインターネットの可用性＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/applying-the-aws-shared-responsibility-model-to-your-gxp-solution/" target="blank">AWS 責任共有モデルを GxP ソリューションに適用する</a><br/>
        ・<a href="https://engineering.mercari.com/blog/entry/20220426-threat-modeling-at-mercari/" target="blank">メルカリの脅威モデリングプロセス＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール 2（AWS におけるアクセスと権限付与）</h5>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202209/way-to-operate-api/" target="blank">AWS の API を理解しよう !　初級編 ~ API の仕組みと利用方法を理解しよう</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202210/way-to-operate-api-2/" target="blank">AWS の API を理解しよう !　中級編 ~ リクエストの署名や CLI/SDK の中身を覗いてみる</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202211/way-to-operate-api-3/" target="blank">AWS の API を理解しよう !　上級編 ~新しい発見があるかも ? いろんな機能をご紹介 !</a><br/>
        ・<a href="https://docs.aws.amazon.com/general/latest/gr/aws-service-information.html" target="blank">Service endpoints and quotas</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cli/latest/userguide/cli-configure-quickstart.html#cli-configure-quickstart-precedence" target="blank">構成設定と優先順位</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- AWS CLI 認証情報と構成設定に関しての優先順位順です。なぜか、IAM で Access Denied になってしまう場合はこちらのドキュメントを参照すると良いかもしれません。<br/>
        ・<a href="https://dev.classmethod.jp/articles/iam-role-passrole-assumerole/" target="blank">IAM ロールの PassRole と AssumeRole をもう二度と忘れないために絵を描いてみた＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/register-flow/" target="blank">AWS アカウント作成の流れ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/accounts/latest/reference/best-practices-root-user.html" target="blank">アカウントのルートユーザーを保護するためのベストプラクティス</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/extend-aws-iam-roles-to-workloads-outside-of-aws-with-iam-roles-anywhere/" target="blank">IAM Roles Anywhere で AWS IAM ロールを AWS 外部のワークロードに拡張する</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/introducing-s3-security-best-practices-1/" target="blank">Amazon S3 セキュリティベストプラクティスの実践（権限管理のポリシー) – 前編</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/introducing-s3-security-best-practices-2/" target="blank">Amazon S3 セキュリティベストプラクティスの実践（権限管理のポリシー) – 後編</a><br/>
        ・<a href="https://awspolicygen.s3.amazonaws.com/policygen.html" target="blank">AWS Policy Generator</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/iam-access-analyzer-makes-it-easier-to-implement-least-privilege-permissions-by-generating-iam-policies-based-on-access-activity/" target="blank">IAM Access Analyzer を利用して、アクセスアクティビティに基づいて IAM ポリシーを生成し、最小限の権限のアクセス許可を簡単に実装する</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">ポリシーの評価論理</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/IAM/latest/UserGuide/reference_policies_evaluation-logic.html" target="blank">How AWS enforcement code logic evaluates requests to allow or deny access</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- 最新の RCP も加えた（2024/11/22　現在）英語版のみのドキュメントです。<br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2017/summit/slide/D2T4-6.pdf" target="blank">KDDI流 クラウド・セキュリティ　〜「⼤企業のクラウド適応」 秘伝のレシピ〜</a><br/>
        ・<a href="https://www.youtube.com/watch?v=oUWnKlRJklE" target="blank">KDDI 流 クラウド・セキュリティ ～「大企業のクラウド適応」 秘伝のレシピ～｜AWS Summit Tokyo 2017＜外部サイト＞</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/solution-casestudy/20160621_AWS-BlackBelt-Authority-public.pdf" target="blank">AWS Black Belt Online Seminar AWSサービスの権限管理</a><br/>
        ・<a href="https://iselegant.hatenablog.com/entry/2020/05/24/215808" target="blank">AWSマルチアカウントにおけるIAMユーザー設計戦略を考えてみる＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202309/learn-lambda-iam-policies/" target="blank">AWS Lambda 関数に適切に AWS IAM のポリシーを設定しよう！</a><br/>
        ・<a href="https://speakerdeck.com/dena_tech/techcon2021-19" target="blank">AWS IAMの属人的な管理からの脱却【DeNA TechCon 2021】/techcon2021-19＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール 3（AWS におけるアカウント管理とプロビジョニング）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_getting-started_concepts.html" target="blank">AWS Organizations の用語と概念</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_integrate_services_list.html" target="blank">AWS Organizations で使用できる AWS のサービス</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/architecture/new-whitepaper-provides-best-practices-for-optimizing-aws-accounts/" target="blank">New Whitepaper Provides Best Practices for Optimizing AWS Accounts　＊英語のみ</a><br/>
        ・<a href="https://docs.aws.amazon.com/whitepapers/latest/organizing-your-aws-environment/benefits-of-using-multiple-aws-accounts.html" target="blank">Benefits of using multiple AWS accounts　＊英語のみ</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/organizations-scp-inheritance/" target="blank">[AWS Organizations] SCP(サービスコントロールポリシー)の継承の仕組みを学ぼう＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/introducing-resource-control-policies-rcps-a-new-authorization-policy/" target="blank">AWS Organizations の新しいタイプの認可ポリシーであるリソースコントロールポリシー (RCP) のご紹介</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_delegate_policies.html" target="blank">AWS Organizations の委任管理者</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/startup/multi-accounts-and-control-tower/" target="blank">スタートアップにおけるマルチアカウントの考え方と AWS Control Tower のすゝめ</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/organizations/latest/userguide/orgs_best-practices.html" target="blank">マルチアカウント管理のベストプラクティス</a><br/>
        ・<a href="https://wa.aws.amazon.com/wellarchitected/2020-07-02T19-33-23/wat.pillar.security.ja.html" target="blank">セキュリティ（from Well-Architected）</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20151021_aws-blackbelt-directory-service.pdf" target="blank">AWS Directory Service</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/202108_AWS_Black_Belt_Microsoft_AD.pdf" target="blank">AWS Managed Microsoft AD</a><br/>
        ・<a href="https://jwt.io/" target="blank">JWT＜外部サイト＞</a><br/>
        <hr/>
        <h5>モジュール 4（AWS におけるキーとシークレットの管理）</h5>
        ・<a href="https://dnsops.jp/event/20140627/DNS_Summer_Days_2014-HSM.pdf" target="blank">暗号鍵を保護するHSM＜外部サイト＞</a><br/>
        ・<a href="https://www.j-lis.go.jp/data/open/cnt/3/2035/1/security.pdf" target="blank">耐タンパー性の概要＜外部サイト＞</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2024_AWS_KeyManagementService_0331_v1.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Key Management Service (AWS KMS)</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202210/awsgeek-kms/" target="blank">データ保護に使う暗号化キーを一元管理。AWS KMS をグラレコで解説</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202502/learn-kms-key-policy/" target="blank"> テクニカルインストラクターと学ぶ AWS KMS のキーポリシー</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/security/digital-signing-asymmetric-keys-aws-kms/" target="blank">Digital signing with the new asymmetric keys feature of AWS KMS</a><br/>
        ・<a href="https://csrc.nist.gov/csrc/media/projects/cryptographic-module-validation-program/documents/security-policies/140sp3139.pdf" target="blank">（旧）FIPS 140-2 Non-Proprietary Security Policy＜外部サイト＞</a><br/>
        ・<a href="https://csrc.nist.gov/CSRC/media/projects/cryptographic-module-validation-program/documents/security-policies/140sp4523.pdf" target="blank">（新）FIPS 140-2 Non-Proprietary Security Policy＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/fips-validation.html" target="blank">FIPS の検証</a><br/>
        ・<a href="https://warp.ndl.go.jp/info:ndljp/pid/12446699/www.ipa.go.jp/security/enc/smartcard/node60.html" target="blank">IV.1.2 セキュリティ要件＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/05/aws-kms-hsm-fips-security-level-3/" target="blank">AWS KMS HSM が FIPS 140-2 セキュリティレベル 3 にアップグレード</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/cloudhsm/latest/userguide/third-party-applications.html" target="blank">サードパーティアプリケーションとAWS CloudHSMの連携</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_AWS-Secrets-Manager_0901_v1.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Secrets Manager</a><br/>
        <hr/>
        <h5>モジュール 5（データセキュリティ）</h5>
        ・<a href="https://aws.amazon.com/jp/compliance/data-center/controls/" target="blank">AWS のコントロール</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20200812_AWS-BlackBelt-Macie.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Macie</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/20200812_AWS-BlackBelt-Macie.pdf#page=28" target="blank">[AWS Black Belt Online Seminar] Amazon Macie（日本語対応に関する部分）</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_Amazon_S3_Security_0131_v1.pdf" target="blank">Amazon Simple Storage Service (Amazon S3)　セキュリティ編</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/01/amazon-s3-automatically-encrypts-new-objects/" target="blank">Amazon S3 ですべての新しいオブジェクトが自動的に暗号化</a><br/>
        ・<a href="https://aws.amazon.com/jp/about-aws/whats-new/2023/04/amazon-s3-security-best-practices-buckets-default/" target="blank">Amazon S3 が新規バケットすべてに 2 つのセキュリティベストプラクティスをデフォルトで適用開始</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/AmazonS3/latest/userguide/security-best-practices.html" target="blank">Amazon S3 のセキュリティのベストプラクティス</a><br/>
        ・<a href="https://www.javadrive.jp/mysql/user/" target="blank">ユーザーの作成＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/applying-best-practices-for-securing-sensitive-data-in-amazon-rds/" target="blank">Amazon RDS で機密データを保護するためのベストプラクティスの適用</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/data_disposal/" target="blank">クラウドにおける安全なデータの廃棄</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/amazonglacier/latest/dev/vault-lock-policy.html" target="blank">ボールトロックポリシー</a><br/>
        <hr/>
        <h5>モジュール 6（インフラストラクチャとエッジ保護）</h5>
        ・<a href="https://docs.aws.amazon.com/ja_jp/wellarchitected/2022-03-31/framework/sec-infrastructure.html" target="blank">インフラストラクチャ保護（SEC 5: ネットワークリソースをどのように保護しますか?）</a><br/>
        ・<a href="https://japan.zdnet.com/article/35057659/" target="blank">自前の変電所まで！--AWS幹部がデータセンターの詳細を紹介＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/vpc/faqs/" target="blank">「Amazon VPC のよくある質問」</a><br/>
        ・<a href="https://aws.amazon.com/jp/elasticloadbalancing/features/" target="blank">Elastic Load Balancing の特徴</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-best-practices-ddos-resiliency/welcome.html" target="blank">DDoS 耐性を獲得するための AWS のベストプラクティス</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/limit-access-to-your-origins-using-the-aws-managed-prefix-list-for-amazon-cloudfront/" target="blank">Amazon CloudFront用のAWS マネージドプレフィックスリストを使用したオリジンへのアクセス制限</a><br/>
        ・<a href="https://pages.awscloud.com/rs/112-TZM-766/images/AWS-Black-Belt_2023_Amazon-Route53-Intro_0530_v1.pdf" target="blank">Amazon Route 53 ~ 導⼊編 ~</a><br/>
        ・<a href="https://www.nic.ad.jp/ja/materials/iw/2014/proceedings/d1/d1-imai.pdf" target="blank">DNS DAY Amazon Route 53</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20170718_AWS-BlackBelt-Shield.pdf#page=17" target="blank">【AWS Black Belt Online Seminar】 AWS Shield (P. 17)</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Standard に関する細かな紹介はその他中々無いものの、当 Blackbelt 資料では紹介されています。貴重な例です。<br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200818_AWS_BlackBelt_AWS_Shield_Advanced.pdf" target="blank">[AWS Black Belt Online Seminar] AWS Shield Advanced</a><br/>
        ・<a href="https://aws.amazon.com/jp/builders-flash/202308/coconala-ddos-countermeasure/" target="blank">ココナラが DDoS 攻撃の脅威を分析し、対策を実現するまで</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Shield Advanced の利用事例は全体的に（公開されているものが）少ないのですが貴重な記事です。<br/>
        ・<a href="https://aws.amazon.com/jp/premiumsupport/jp_shield_support/" target="blank">AWS Shield Advanced における日本語サポートの制約事項</a><br/>
        &nbsp;&nbsp;&nbsp;&nbsp;- Shield Advanced における日本語サポートの制約事項です。ご検討の場合はご留意ください。<br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-best-practices-ddos-resiliency/welcome.html" target="blank">DDoS 耐性を獲得するための AWS のベストプラクティス</a><br/>
        <hr/>
        <h5>モジュール 7（AWS におけるログのモニタリングと収集）</h5>
        ・<a href="https://fdsa-life.jp/blog/?p=1629" target="blank">リスクの統制「予防と発見」を活用する。【監査技術の応用】＜外部サイト＞</a><br/>
        ・<a href="https://www.ipa.go.jp/security/reports/oversea/nist/ug65p90000019cp4-att/begoj9000000d400.pdf" target="blank">The NIST Cybersecurity Framework (CSF) 2.0 from IPA＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/managed-rules-by-aws-config.html" target="blank">AWS Config マネージドルールのリスト</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/config/latest/developerguide/conformance-packs.html" target="blank">コンフォーマンスパック</a><br/>
        ・<a href="https://speakerdeck.com/nagahara/continuous-compliance-with-aws-config" target="blank">AWS Config による継続的コンプライアンス実現に向けた取り組み / Continuous Compliance With AWS Config＜外部サイト＞</a><br/>
        ・<a href="https://speakerdeck.com/fu3ak1/awsnosekiyuriteisabisuwoxue-bu" target="blank">AWSのセキュリティサービスを学ぶ＜外部サイト＞</a><br/>
        ・<a href="https://aws.amazon.com/jp/cloudwatch/features/" target="blank">Amazon CloudWatch の特徴</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/news/new-amazon-cloudwatch-cross-account-observability/" target="blank">New – Amazon CloudWatch のクロスアカウントオブザーバビリティ</a><br/>
        ・<a href="https://mazyu36.hatenablog.com/entry/2023/03/17/184805" target="blank">個人的AWS ログ管理のベースライン</a><br/>
        ・<a href="https://tech.nri-net.com/entry/2021/05/26/102844" target="blank">IAMユーザーのアクセスキー作成を簡単に通知して敏感になる＜外部サイト＞</a><br/>
        ・<a href="https://www.datadoghq.com/ja/blog/aws-monitoring/" target="blank">AWS 監視のための主要なメトリクス＜外部サイト＞</a><br/>
        ・<a href="https://d1.awsstatic.com/webinars/jp/pdf/services/20200617_BlackBelt_Amazon_Athena.pdf" target="blank">[AWS Black Belt Online Seminar] Amazon Athena</a><br/>
        ・<a href="https://catalog.us-east-1.prod.workshops.aws/workshops/60a6ee4e-e32d-42f5-bd9b-4a2f7c135a72/ja-JP" target="blank">SIEM on Amazon OpenSearch Service Workshop</a><br/>
        <hr/>
        <h5>モジュール 8（脅威への対応）</h5>
        ・<a href="https://www.jpcert.or.jp/csirt_material/files/manual_ver1.0_20211130.pdf" target="blank">インシデントハンドリングマニュアル＜外部サイト＞</a><br/>
        ・<a href="https://nvlpubs.nist.gov/nistpubs/specialpublications/nist.sp.800-61r2.pdf" target="blank">Computer Security Incident Handling Guide＜外部サイト＞</a><br/>
        ・<a href="https://github.com/happon/NIST-800-61-Rev.2-Computer-Security-Incident-Handling-Guide-In-Japanese" target="blank">NIST-800-61-Rev.2-日本語＜外部サイト＞</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/whitepapers/latest/aws-security-incident-response-guide/welcome.html" target="blank">AWS セキュリティインシデント対応ガイド</a><br/>
        ・<a href="https://aws.amazon.com/jp/blogs/aws/new-aws-security-incident-response-helps-organizations-respond-to-and-recover-from-security-events/" target="blank">New AWS Security Incident Response helps organizations respond to and recover from security events</a><br/>
        ・<a href="http://dmiyakawa.github.io/blog/2014/05/02/your-aws-account-is-compromisednau/" target="blank">Your AWS Account Is Compromisedなう＜外部サイト＞</a><br/>
        ・<a href="https://repost.aws/ja/knowledge-center/report-aws-abuse" target="blank">AWS リソースの不正行為を報告するにはどうすればよいですか?</a><br/>
        ・<a href="https://aws.amazon.com/jp/security/penetration-testing/" target="blank">侵入テスト</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/securityhub/latest/userguide/securityhub-partner-providers.html#integrations-third-party-summary" target="blank">サードパーティーの Security Hub との統合の概要</a><br/>
        ・<a href="https://aws.amazon.com/jp/solutions/implementations/automated-security-response-on-aws/" target="blank">AWS での自動化されたセキュリティ対応</a><br/>
        ・<a href="https://docs.aws.amazon.com/ja_jp/guardduty/latest/ug/guardduty_finding-types-active.html" target="blank">検出結果タイプ</a><br/>
        ・<a href="https://d1.awsstatic.com/events/jp/2021/summit-online/AWS-39_AWS_Summit_Online_2021_SEC03.pdf" target="blank">AWS 環境における脅威検知と対応</a><br/>
        ・<a href="https://dev.classmethod.jp/articles/guardduty-detect-exfiltration-ec2-credentials-inside/" target="blank">[アップデート]GuardDutyが盗まれたEC2のクレデンシャルが別AWSアカウントで利用されたことを検知できるようになったので実際に試してついでにDetectiveで調査してみた＜外部サイト＞</a><br/>
        <hr/>
        <h5>その他（総合的に順不同）</h5>
        ・<a href="https://d1.awsstatic.com/whitepapers/ja_JP/compliance/NIST_Cybersecurity_Framework_CSF.pdf" target="blank">NIST サイバーセキュリティフレームワーク (CSF)</a><br/>
        ・<a href="https://maturitymodel.security.aws.dev/en/model/" target="blank">AWS Security Maturity Model</a><br/>
        ・<a href="https://aws.amazon.com/security/security-bulletins/" target="blank">Security Bulletins</a><br/>
        ・<a href="https://aws-samples.github.io/jp-contents-hub/" target="blank">AWS 日本語ハンズオン</a><br/>
      </div>
      <hr class="koji"/>
      <h3 id="survey" class="anchor">アンケート</h3>
      <div class="box">
        <h5>アンケートの入力</h5>
        <ol>
          <li><a href="https://www.aws.training">https://www.aws.training</a> へアクセス</li>
          <li>「サインイン」※一般、もしくはAPNパートナー　でサインイン ボタンが異なります</li>
          <li>アカウント > トレーニングデータ　をクリック</li>
          <li><b>アーカイブ済み</b> タブ　で受講したトレーニングを表示</li>
          <li><b>評価</b>　をクリック</li>
          <li>アンケートとコメントを入力</li>
        </ol>
        <img src="survey.png" />
      </div>
      <hr class="koji"/>
      <h3 id="faq" class="anchor">FAQ</h3>
      <div class="box">
        <h5>トレーニングテキスト</h5>
        Q. トレーニングテキストはいつまで参照できますか？<br/>
        &nbsp;&nbsp;・ダウンロードから720日間参照できます。<br/><br/>
        Q. トレーニングテキストは印刷できますか？<br/>
        &nbsp;&nbsp;・Bookshelf というアプリケーションを利用することで印刷が可能です。ただし、一度に印刷できるのは 50 ページの制限があります。無断配布は禁じられております。<br/>
        &nbsp;&nbsp;・<a href="http://awsj-tc-training-public.s3-ap-northeast-1.amazonaws.com/vILT/common/GTT_eVantage_Bookshelf_ver2.pdf" target="blank">Bookshelfの入手方法はこちら (PDF)</a><br/>
        &nbsp;&nbsp;※👆の資料のコードによる引き換えは現在方式として（当コースの場合）運用が変更されております。前述の「受講者ポータル」より「受講者ガイド」および「ラボガイド」を引き換え下さい。（要 Bookshelf 登録）
        <hr/>
        <h5>Rocket Chat</h5>
        Q. チャットの内容はいつでも参照できますか？<br/>
        &nbsp;&nbsp;・いいえ、トレーニング最終日の翌日を目処に削除します。（トレーニング内で別途ご案内致します）
      </div>
    </main>
  );
};

export default MainContents;
